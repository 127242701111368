const brand = {
  key: 'halchimy',
  accessKey: process.env.REACT_APP_HALCHIMY_ACCESS_KEY,
  name: 'Halchimy',
  website: {
    url: 'https://halchimy.com',
    domain: 'Halchimy.com',
  },
  contactUrl: 'https://halchimy.com/contact/',
  features: {
    withUidCheck: false,
    withIssueRequest: true,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1716286226/Halchimy/u47cw1ayyvyptigbzupk.png',
    homePage: null,
    homePageCompressed: null,
    certificateExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237023/certificate-request-page/brands/halchimy/certificate-example_gcou2y.png',
    certificateExampleCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237018/certificate-request-page/brands/halchimy/certificate-example-compressed_z1yhpz.png',
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237015/certificate-request-page/brands/halchimy/favicon_koffrz.png',
    uidExample: null,
  },
  socialLinks: {
    facebook: 'https://www.facebook.com/HalchimyOfficiel',
    twitter: 'https://twitter.com/halchimy',
    instagram: 'https://www.instagram.com/halchimy',
    linkedin: 'https://www.linkedin.com/company/halchimy',
  },
};

export default brand;
